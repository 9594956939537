/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-10",
    versionChannel: "nightly",
    buildDate: "2023-01-10T00:15:26.678Z",
    commitHash: "14ee9b43fb077d73d332db2fdef705be1f687958",
};
